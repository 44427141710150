<template>
  <v-app class="layout-wrapper">
    <SplashScreen />
    <AppBar />
    <v-main>
      <router-view
        :key="$route.fullPath"
      />
    </v-main>
    <FloatingNav />
    <BottomNav v-show="$vuetify.breakpoint.xsOnly" />
    <Footer />
    <ContactCenterDialog />
    <CarerDetailProfileDialog />
    <RequestProfileDialog />
    <Popup />
  </v-app>
</template>

<script>
import { Api } from '../../../libraries/api'
import AppBar from './AppBar'
import Footer from './Footer'
import Popup from './Popup'
import BottomNav from './BottomNav'
import FloatingNav from './FloatingNav'
import SplashScreen from './Splash'
import ContactCenterDialog from '../../_common/ContactCenterDialog'
import CarerDetailProfileDialog from '../../caregiver/DetailProfileDialog'
import RequestProfileDialog from '../../caregiver/RequestProfileDialog'

export default {
  name: 'DefaultLayout',

  components: {
    Popup,
    AppBar,
    Footer,
    BottomNav,
    FloatingNav,
    SplashScreen,
    ContactCenterDialog,
    CarerDetailProfileDialog,
    RequestProfileDialog
  },

  data: () => ({
  }),
  async created() {
    await this.getAppInfo()
  },
  mounted() {
    this.$vuetify.theme.dark = false
  },
  methods: {
    async getAppInfo() {
      const url = '/app/info'
      const result = await Api().get(url, { cache: true })
      const { item } = result.data.data
      this.$store.dispatch('App/AppInfo', item)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
