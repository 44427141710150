import Vue from 'vue'

import { sync } from 'vuex-router-sync'
import i18n from './libraries/i18n'
import store from './libraries/vuex'
import vuetify from './libraries/vuetify'
import './libraries/cookie'
import './libraries/dayjs'
import './libraries/filters'
import './libraries/sentry'
import './libraries/event-bus'
import './libraries/clipboard'
import './libraries/editor/mavon-editor'
// import './registerServiceWorker'
import mixins from './libraries/mixins'

// eslint-disable-next-line import/no-cycle
import { setUserAccessToken } from './libraries/api'

import App from './App'
import router from './modules/router'

sync(store, router)
Vue.config.productionTip = false

const app = new Vue({
  mixins: [mixins],
  i18n,
  store,
  router,
  vuetify,
  beforeCreate() {
    // this.$vuetify.theme.dark = 'light'
    // this.$vuetify.theme.dark = (this.$store.state.App.currentTheme === 'dark')
    setUserAccessToken(this.$store.state.Account.auth.token)
    const localeInCookie = this.$cookies.get('locale')
    this.$i18n.locale = localeInCookie || process.env.VUE_APP_I18N_LOCALE
  },
  render: (h) => h(App)
}).$mount('#app')

export default app
