/* eslint import/no-cycle: [0, { ignoreExternal: true }] */
import _ from 'lodash'
import axios from 'axios'
// import { cacheAdapterEnhancer } from 'axios-extensions'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import app from '../../main'
import Config from '../../config'

const option = {
  headers: {
    userAccessToken: ''
  }
}

function setInterceptors(axiosApi) {
  axiosApi.interceptors.request.use(
    (config) => {
      // app.$Progress.start()
      app.$bus.$emit('isProgressView', true)
      // Do something before request is sent
      return config
    },
    (error) => {
      // app.$Progress.start()
      app.$bus.$emit('isProgressView', true)
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  axiosApi.interceptors.response.use(
    (response) => {
      // app.$Progress.finish()
      app.$bus.$emit('isProgressView', false)
      // Do something with response data
      return response
    },
    (error) => {
      // app.$Progress.fail()

      app.$bus.$emit('isProgressView', false)
      // Do something with response error
      return Promise.reject(error)
    }
  )
  return axiosApi
}

function AuthApi() {
  const headers = {
    'Cache-Control': 'no-cache',
    Authorization: ''
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': true,
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  }

  if (Config.App.app_access_key) {
    headers['app-token'] = Config.App.app_access_key
  }

  if (option.headers.userAccessToken) {
    headers['app-token'] = Config.App.app_access_key
    headers.Authorization = `Bearer ${option.headers.userAccessToken}`
  }

  let axiosApi = axios.create({
    // baseURL: `/api`, // proxy mode. See /client/config/index.js
    baseURL: Config.Api.MsaAuth.url,
    headers
  })

  axiosApi = setInterceptors(axiosApi)

  return axiosApi
}

function FilebuketApi() {
  const headers = {
    Authorization: ''
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': true,
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  }
  if (option.headers.userAccessToken) {
    headers.Authorization = `Bearer ${option.headers.userAccessToken}`
  }

  let axiosApi = axios.create({
    // baseURL: `/api`, // proxy mode. See /client/config/index.js
    baseURL: Config.Api.MsaFilebucket.url,
    headers
  })

  axiosApi = setInterceptors(axiosApi)

  return axiosApi
}

const axiosServiceApi = axios.create({
  baseURL: Config.Api.Service.url,
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false }))
})

function Api() {
  let api = axiosServiceApi
  const headers = {
    'Cache-Control': 'no-cache',
    Authorization: ''
  }

  api.defaults.headers = headers
  api = setInterceptors(api)
  return api
}

function hasAccessToken() {
  return option.headers.userAccessToken !== ''
}

function getUserAccessToken() {
  return option.headers.userAccessToken
}

function setUserAccessToken(token) {
  option.headers.userAccessToken = token
  // console.log(`set access token : ${option.headers.userAccessToken}`)
}

function initializeAccesstoken() {
  option.headers.userAccessToken = ''
  // console.log(`initialize access token : ${option.headers.userAccessToken}`)
}

function convertFormData(obj) {
  const dt = new FormData()
  Object.keys(obj).forEach((key, value) => {
    dt.append(key, value)
  })
  return dt
}

function cleanObject(obj) {
  const dt = _.pickBy(_.cloneDeep(obj))
  return dt
}

/**
 * makeGetUrl (url, paramObj)
 *
 * @description
 * object 를 parameter 로 붙여 url 만들어 리턴합니다.
 *
 * @param {String} url - url
 * @param {Object} paramObj - parameter data
 */
function makeGetUrl(url, paramObj) {
  const paramStr = Object.entries(cleanObject(paramObj))
    .map(([k, v]) => `${k}=${v}`)
    .join('&')
  return `${url}?${paramStr}`
}

export {
  Api,
  AuthApi,
  FilebuketApi,
  makeGetUrl,
  hasAccessToken,
  convertFormData,
  getUserAccessToken,
  setUserAccessToken,
  initializeAccesstoken
}
