/**
 * Configuration Files (Production mode)
 */

// store 업데이트로 인해 재 로그인이 필요한 경우 version을 올리고 아래 값을 true로 변경 필요
const version = 0.1
const isNeedReSignIn = true

export default {
  App: {
    stage: 'production',
    title: '간병매칭플랫폼 헬퍼',
    description: '아무에게나 사랑하는 사람을 맡기지 마세요. 간병인의 정보를 보고 직접 받아보시고 선택하세요. 간병인 구할 땐 간병매칭플랫폼 헬퍼!',
    app_access_key: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBfaWR4Ijo4LCJleHAiOjE2NTI0MDQ5MTJ9.waS3_dfAxWwwdVWF3uLe1ivWnMotfEv-OyWtMdQQ6vI',
    Meta: {
      title: '간병매칭플랫폼 헬퍼',
      separation: '|'
    },
    Cdn: {
      url: 'https://cdn.thehelper.io'
    },
    version,
    isNeedReSignIn,
    cookieDomain: '.thehelper.io',
    supportMailAddress: 'help@thehelper.io'
  },
  Api: {
    MsaAuth: {
      appId: '1ed09b33-34f4-411c-a49b-fc0c41b16b55',
      url: 'https://msa-auth.sothecode.com'
    },
    MsaFilebucket: {
      url: 'https://msa-filebucket.sothecode.com'
    },
    Service: {
      url: 'https://api.thehelper.io'
    }
  },
  Sentry: {
    enable: false,
    dsn: 'https://',
    environment: 'production',
    project: '',
    release: '0.1.0'
  },
  Google: {
    oAuth: {
      clientId: '',
      clientKey: ''
    }
  },

  Facebook: {
    oAuth: {
      clientId: '',
      clientKey: ''
    }
  },
  Iamport: {
    id: '',
    key: '',
    secret: '',
    pgId: '',
    pgSecret: '',
    pgCancelPassword: ''
  }
}
