// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

function importPage(fp) {
  // return () => import(/* webpackChunkName: "view-[request].[chunkhash]" */ `@/modules/${fp}.vue`)
  return () => import(/* webpackChunkName: "[index]" */ `@/modules/${fp}.vue`)
}
export default importPage
