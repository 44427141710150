import { Api } from '@/libraries/api'
// import { Api, makeGetUrl } from '@/libraries/api'

function getAddressItemSchema(addressIdx) {
  return {
    area: "",
    idx: addressIdx,
    is_display: true,
    parent_idx: null,
    short_name: ""
  }
}

async function getAddressList(parentIdx = 0) {
  const tailPath = (parentIdx > 0) ? `/detail/${parentIdx}` : ''
  const url = `extra/address${tailPath}`
  const result = await Api().get(url, { cache: true })
  const { items } = result.data.data
  return items
}

async function getAddressByIdx(addressIdx = 0) {
  const url = `extra/address/single/${addressIdx}`
  try {
    const result = await Api().get(url, { cache: true })
    const { item } = result.data.data
    return item
  } catch (e) {
    return getAddressItemSchema(addressIdx)
  }
}

async function getAddressItem(addressIdx = 0) {
  const url = `extra/address/${addressIdx}`
  try {
    const result = await Api().get(url, { cache: true })
    const { item } = result.data.data
    return item
  } catch (e) {
    return getAddressItemSchema(addressIdx)
  }
}

async function getConvertedAddressText(areaIdx, areaDetailIdx) {
  let address = '-'
  try {
    const areaItem = await getAddressByIdx(areaIdx)
    const detailItem = await getAddressByIdx(areaDetailIdx)
    address = `${areaItem.short_name}`
    if (areaItem.area === '전체') {
      address = '전체'
    } else if (detailItem.area !== '전체') {
      address += ` ${detailItem.short_name}`
    }
  } catch (e) {
    return address
  }
  return address
}

export {
  getAddressList,
  getAddressItem,
  getAddressByIdx,
  getConvertedAddressText
}
