<template>
  <v-app-bar
    app
    flat
    color="#FFF"
    :class="{'elevation-4': isActiveElevation}"
    :style="''"
  >
    <v-container>
      <v-row class="justify-center  justify-sm-space-between align-center">
        <v-col
          class="ml-3"
          cols="auto"
        >
          <v-toolbar-title
            class="title-with-link"
            @click="$router.push('/').catch(err =>{})"
          >
            <v-img
              :src="require(isDarkTheme ? './assets/logo-full@3x.png' : './assets/logo-full@3x.png')"
              width="66"
              height="26"
            />
          </v-toolbar-title>
        </v-col>
        <v-col
          v-show="!$vuetify.breakpoint.xsOnly"
          cols="auto"
          class="mr-3"
        >
          <v-btn
            large
            depressed
            color="#007AFF"
            class="mx-4 white--text"
            @click="$router.push('/').catch(() => {})"
          >
            간병인 찾기
          </v-btn>
          <v-btn
            large
            depressed
            color="#00BC98"
            class="float-0 white--text"
            @click="$router.push('/findCaregiver').catch(() => {})"
          >
            간병일자리 찾기
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  components: {
  },
  data: () => ({
    isDarkTheme: true,
    isActiveElevation: false,
    alpha: 'rgba(255, 255, 255, 0.0)'
  }),
  watch: {
    $route(to) {
      this.isDarkTheme = (to.name === 'Product.Index')
    }
  },
  mounted() {
    const self = this
    this.$bus.$on('isDarkMode', (isDarkMode) => {
      self.isDarkMode = isDarkMode
    })
    window.onscroll = () => {
      this.changeOpacity()
    }
  },
  methods: {
    changeOpacity() {
      const limit = 72
      let opacity = 0.0
      if (document.documentElement.scrollTop <= limit) {
        opacity = Math.floor((document.documentElement.scrollTop / limit) * 100) / 100
        this.isActiveElevation = false
      } else {
        opacity = 1.0
        this.isActiveElevation = true
      }

      this.alpha = `rgba(255, 255, 255, ${opacity})`
    }
  }

}
</script>

<style lang="scss" scoped>
.title-with-link {
  cursor: pointer;
}
</style>
