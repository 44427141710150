import Vue from "vue"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import Config from '../../config'

if (Config.Sentry.enable === true) {
  Sentry.init({
    Vue,
    dsn: Config.Sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    project: Config.Sentry.project,
    release: Config.Sentry.release,
    environment: Config.Sentry.environment,
    tracingOptions: {
      trackComponents: true
    }
  })
}

/*
 * Example
 */
// import * as Sentry from "@sentry/vue";

// Capturing Errors
// try {
//   aFunctionThatMightFail();
// } catch (err) {
//   Sentry.captureException(err);
// }

// Capturing Message
// Sentry.captureMessage("Something went wrong");
