export default [
  // {
  //   title: 'About',
  //   link: '/about',
  //   isVisibleInFooter: true
  // },
  // {
  //   title: 'Product',
  //   link: '/product',
  //   isVisibleInFooter: true
  // },
  // {
  //   title: 'Simulator',
  //   link: '/simulator',
  //   isVisibleInFooter: true
  // },
  {
    title: {
      ko: 'Home',
      en: 'Home'
    },
    link: '/',
    isShowRawCase: false,
    isVisibleInFooter: true,
    bottomNavIcon: 'fa-home'
  },
  // {
  //   title: {
  //     ko: 'Category',
  //     en: 'Category'
  //   },
  //   link: '/category',
  //   isShowRawCase: true,
  //   isVisibleInFooter: true,
  //   // bottomNavIcon: 'fa-poll'
  //   bottomNavIcon: 'fa-layer-group'
  // },
  {
    title: {
      ko: 'Sign In',
      en: 'Sign In'
    },
    link: '/account/signin',
    isShowRawCase: false,
    isVisibleInFooter: true,
    bottomNavIcon: 'fa-user'
  }
]
