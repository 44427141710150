/* eslint-disable prefer-spread */
/* eslint-disable no-shadow */
import Vue from 'vue'
import _ from 'lodash'

const eventBus = {}

// @ts-ignore
eventBus.install = (Vue) => {
  // @ts-ignore
  const bus = new Vue()

  // @ts-ignore
  Object.defineProperty(Vue.prototype, '$bus', {
    // for "this.$bus"
    get() {
      return bus
    },
    // alt way to send an event (this.$bus=['event_name',arg1,arg2])
    set(evt) {
      const events = (typeof evt === 'string') ? [evt] : evt
      bus.$emit.apply(bus, events)
    }
  })

  // @ts-ignore
  Vue.mixin({
    created() {
      // add option "$bus" instead bus.$on in created hook
      const { $bus } = this.$options
      this.$busListeners = {}
      _.forEach($bus, (name) => {
        // rebind and remember each declared listener
        this.$busListeners[name] = $bus[name].bind(this)
        // register a listener for the event
        bus.$on(name, this.$busListeners[name])
      })
      // for (const name in $bus) {
      //   // rebind and remember each declared listener
      //   this.$busListeners[name] = $bus[name].bind(this)
      //   // register a listener for the event
      //   bus.$on(name, this.$busListeners[name])
      // }
    },
    beforeDestroy() {
      // unreg listeners
      _.forEach(this.$busListeners, (name) => {
        bus.$off(name, this.$busListeners[name])
      })
      // for (const name in this.$busListeners) {
      //   bus.$off(name, this.$busListeners[name])
      // }
      this.$busListeners = null
    }
  })
}

// @ts-ignore
Vue.use(eventBus)
