import Vue from 'vue'
import 'dayjs/locale/ko'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.locale('ko')

// dayjs.extend(window.dayjs_plugin_relativeTime)

Vue.filter('dayjsDatetime', (value) => {
  if (!value) return 'n/a'
  // return dayjs(value).fromNow()
  return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('dayjsDate', (value) => {
  if (!value) return 'n/a'
  // return dayjs(value).fromNow()
  return dayjs(value).format('YYYY-MM-DD')
})

Vue.filter('dayjsTime', (value) => {
  if (!value) return 'n/a'
  // return dayjs(value).fromNow()
  return dayjs(value).format('HH:mm:ss')
})

Vue.filter('dayjsFromNow', (value) => {
  dayjs.extend(relativeTime)
  if (!value) return 'n/a'
  return dayjs(value).fromNow()
})
