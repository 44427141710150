import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/terms',
    name: 'Terms.Index',
    redirect: '/terms/terms_of_service'
  },
  {
    path: '/terms/terms_of_service',
    name: 'Terms.TermsOfService',
    component: importPage('terms/Terms'),
    meta: {
      title: '서비스 이용약관',
      scrollToTop: true
    }
  },
  {
    path: '/terms/terms_of_privacy',
    name: 'Terms.TermsOfPrivacy',
    component: importPage('terms/Terms'),
    meta: {
      title: '개인정보취급방침',
      scrollToTop: true
    }
  }
]
