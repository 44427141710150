<template>
  <div>
    <!-- :fullscreen="$vuetify.breakpoint.xsOnly" -->
    <v-dialog
      v-model="dialog"
      width="100%"
      max-width="600"
      hide-overlay
    >
      <!-- overlay-color="#000000"
      overlay-opacity="0" -->
      <v-card>
        <v-container class="pa-0">
          <v-row
            align="center"
            justify="center"
            style=""
            no-gutters
          >
            <v-col
              cols="12"
            >
              <img
                :src="value.image_url1"
                style="width: 100%;"
              >
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions
          class="pa-0"
          style="height: 60px;"
        >
          <v-container
            fluid
            class="pa-0"
          >
            <v-row no-gutters>
              <v-col
                cols="6"
                class="pl-4"
              >
                <v-btn
                  plain
                  depressed
                  large
                  @click="isShowPopupAgain = !isShowPopupAgain"
                >
                  <v-icon left>
                    {{ isShowPopupAgain === true ? 'check_circle' : 'radio_button_unchecked' }}
                  </v-icon>
                  오늘 하루 보지 않기
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="pr-4 text-right"
              >
                <v-btn
                  plain
                  text
                  depressed
                  large
                  @click="closeDialog"
                >
                  닫기
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          admin_idx: 1,
          created_at: "2021-07-08T05:51:59",
          idx: 5,
          image_url1: "",
          image_url2: "",
          is_activate: 1,
          is_delete: 0,
          promotion_type: "popup",
          sequence_info: 1
        }
      }
    },
    cursor: {
      type: Number,
      default: -1
    },
    isDisplay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      isShowPopupAgain: false
    }
  },
  watch: {
    isDisplay(newValue) {
      this.dialog = newValue
    }
  },
  async created() {
    this.dialog = this.isDisplay
  },
  methods: {
    closeDialog() {
      this.dialog = false
      if (this.isShowPopupAgain === true) {
        const date = new Date()
        date.setDate(date.getDate() + 1)
        this.$cookies.set(`popup-${this.value.idx}`, 'alreadyShown', date)
      }
      this.$emit('closeDialog', this.cursor)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 0px !important;
  // border-top-left-radius: 10px !important;
  // border-bottom-left-radius: 10px !important;
  // border-top-right-radius: 0 !important;
  // border-bottom-right-radius: 0 !important;
}
.bottom-card-action {
  height: 60px !important;
  background: #007AFF;
}

</style>
