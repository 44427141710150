import _ from 'lodash'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Config from '@/config'
import store from '@/libraries/vuex'
import scrollBehavior from '@/libraries/router/scrollBehavior'

import MainRouter from './main/router'
import RequestRouter from './request/router'
import FindCaregiverRouter from './findCaregiver/router'
import AccountRouter from './account/router'
import TermsRouter from './terms/router'

Vue.use(VueRouter)

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes: [
    ...AccountRouter,
    ...MainRouter,
    ...RequestRouter,
    ...FindCaregiverRouter,
    ...TermsRouter
  ]
})

Router.beforeEach((to, from, next) => {
  const sa = store.state.Account

  // vue-router 에서 history 구현할 수 없어 store 를 이용해 저장
  store.dispatch('App/addRouterHistory', {
    name: from.name,
    path: from.path,
    fullPath: from.fullPath
  })

  // const redirectRootPageMeta = {
  //   path: '/'
  // }
  const redirectSignInPageMeta = {
    path: '/account/signin',
    query: { redirect: to.fullPath }
  }
  const redirectConsolePageMeta = {
    path: '/dashboard/main'
  }

  try {
    document.title = Config.App.title
    if (to.meta.title) {
      document.title = `${to.meta.title} | ${Config.App.title}`
    }
  } catch (e) {
    document.title = `${Config.App.title}`
  }

  try {
    const descriptionMeta = document.querySelector('meta[name="description"]')
    descriptionMeta.content = Config.App.description || ''
  } catch (e) {
    console.log(e)
  }

  // 로그인 되어 있는 경우, 로그인 체크
  const redirectConsolePageNeedRouters = ['/account/signin', '/account/signup']

  // // meta태그 기준 접근권한에 따라 페이지 리다이렉트 진행
  if (_.includes(redirectConsolePageNeedRouters, to.path) && sa.isSignedIn === true) {
    next(redirectConsolePageMeta)
  } else if (to.matched.some((o) => o.meta.requireSignin) && !sa.isSignedIn) {
    next(redirectSignInPageMeta)
  } else if (to.matched.some((o) => o.meta.requireAdmin) && !store.getters['Account/isAdmin']) {
    next(false)
  } else if (to.matched.some((o) => o.meta.requireManufacturer) && !store.getters['Account/isManufacturer'] && !store.getters['Account/isAdmin']) {
    next(false)
  } else {
    next()
  }
})

export default Router
