import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/add/patient',
    name: 'Request.LegacyPath',
    redirect: '/request'
  },
  {
    path: '/request',
    name: 'Request.Index',
    component: importPage('request/Main'),
    meta: {
      title: '간병인 요청하기',
      scrollToTop: true
    }
  }
]
