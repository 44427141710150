/**
 * @description truncate text to a specified length.
 * **notice: the length donot include the length of ellipsis string,
 * this mean the origin string will but cat to specific length and then append the ellipsis string**
 * the default length is 30, default ellipsis string is `'...'`
 * @example
 * ```
 * {{ 'this is a big city!' | truncate(12) }} => 'this is a bi...`
 * {{ 'this is a big city!' | truncate(12, '~~~') }} => 'this is a bi~~~`
 * ```
 */
export default function (str = '', len = 20, ellipsis = '...') {
  try {
    return str.length > len ? `${str.slice(0, len)}${ellipsis}` : str
  } catch (e) {
    return ''
  }
}
