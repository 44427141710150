<template>
  <div>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      width="100%"
      max-width="600"
      overlay-color="#000000"
      overlay-opacity="0.8"
    >
      <v-card>
        <v-container>
          <v-row
            justify="end"
            align="center"
          >
            <v-col cols="auto">
              <v-btn
                icon
                @click="dialog=false"
              >
                <v-icon>
                  close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            align="center"
            justify="center"
            style=""
          >
            <v-col
              cols="3"
              class="pl-4 pl-sm-8 pl-md-12"
            >
              <!-- :class="{'mobile': ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)}" -->
              <img
                :src="getImageUrl(item.extra_info.profile_image) || require(`./assets/illust_${(item.basic_info.gender === 'male') ? 'man' : 'woman'}.png`)"
                style="max-width: 100%; min-width: 50px; max-height: 100px; witdh:100%; border-radius: 50%;"
              >
            </v-col>
            <v-col>
              <v-container class="pl-0 pl-sm-2">
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-2"
                  >
                    <span class="text-subtitle-1 font-weight-bold mr-2">{{ item.basic_info.name | nameConv }}</span>
                    <span class="text-body-2">간병사님</span>
                  </v-col>
                </v-row>
                <v-row
                  v-show="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                  class="mt-0"
                >
                  <v-col
                    cols="auto"
                    class=""
                  >
                    <div class="left-icon-age">
                      <span class="label-key">나이</span>
                      <span class="label-value">{{ item.basic_info.age }}세</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="auto"
                    class=""
                  >
                    <div class="left-icon-gender">
                      <span class="label-key">성별</span>
                      <span class="label-value">{{ item.basic_info.gender_conv }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="auto"
                    class=""
                  >
                    <div class="left-icon-carrer">
                      <span class="label-key">경력</span>
                      <span class="label-value">{{ item.extra_info.career }}년</span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row
            v-show="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
            no-gutters
            align="center"
            justify="space-between"
            justify-sm="start"
            class="pt-4"
          >
            <v-col
              cols="auto"
              class="pl-0 px-sm-4"
            >
              <!-- <div :class="{ 'left-icon-age': $vuetify.breakpoint.sm }"> -->
              <div class="left-icon-age">
                <span
                  v-show="$vuetify.breakpoint.sm"
                  class="label-key"
                >나이</span>
                <span class="label-value">{{ item.basic_info.age }}세</span>
              </div>
            </v-col>
            <v-col
              cols="auto"
              class="px-4 px-sm-4"
            >
              <div class="left-icon-gender">
                <span
                  v-show="$vuetify.breakpoint.sm"
                  class="label-key"
                >성별</span>
                <span class="label-value">{{ item.basic_info.gender_conv }}</span>
              </div>
            </v-col>
            <v-col
              cols="auto"
              class="px-4 px-sm-4"
            >
              <div class="left-icon-carrer">
                <span
                  v-show="$vuetify.breakpoint.sm"
                  class="label-key"
                >경력</span>
                <span class="label-value">{{ item.extra_info.career }}년</span>
              </div>
            </v-col>
          </v-row>
          <v-row
            style="border-top: 1px solid #CCC; margin-top: 28px; padding-top: 18px;"
          >
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              자격증
            </v-col>
            <v-col class="pr-4 pr-sm-8 pr-md-12">
              {{ item.extra_info.license || '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              지역
            </v-col>
            <v-col class="pr-4 pr-sm-8 pr-md-12">
              {{ convertedAddress }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              근무시간
            </v-col>
            <v-col class="pr-4 pr-sm-8 pr-md-12">
              {{ item.extra_info.office_hour_conv }}
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              희망급여
            </v-col>
            <v-col>
              {{ item.extra_info.desired_salary }}
            </v-col>
          </v-row> -->
          <v-row>
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              근무내역
            </v-col>
            <v-col class="pr-4 pr-sm-8 pr-md-12">
              {{ $options.filters.numberFormat(item.work_info.number_of_completions) }}건
            </v-col>
          </v-row>
          <v-row class="pb-8">
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              자기소개
            </v-col>
            <v-col
              class="pr-4 pr-sm-8 pr-md-12"
            >
              {{ item.extra_info.introduce }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="px-4 px-sm-8 px-md-12"
            >
              <div class="mb-4">
                연락처
              </div>
              <v-btn
                block
                height="60px"
                class="elevation-0 mb-2"
                style="border: 1px solid #025BBC; border-radius: 5px; background: #025BBC; color: #FFF;"
                @click="dial"
              >
                간병사님에게 전화하기
              </v-btn>
              <div class="text-label-contact-guide text-body-2">
                * 꼭! 간병사님에게 전화를 해보신 뒤에 결정해주시기 바랍니다.
              </div>
            </v-col>
          </v-row>

          <v-row
            v-show="false"
            class="pb-0"
          >
            <v-col
              cols="12"
              class="px-4 px-sm-8 px-md-12"
            >
              <v-btn
                block
                height="60px"
                class="elevation-0"
                style="border: 1px solid #9e9e9e; background: #f9f9f9;"
                :href="`tel:${getCSCenterPhoneNumber()}`"
              >
                <p
                  v-show="!$vuetify.breakpoint.xsOnly"
                  class="call-center-title my-0"
                  style="color: #2c2c2c;"
                >
                  고객센터
                </p>
                <div
                  v-show="!$vuetify.breakpoint.xsOnly"
                  class="px-4"
                  style="color: #2c2c2c;"
                >
                  |
                </div>
                <div
                  :class="{ 'call-center-title': $vuetify.breakpoint.xsOnly }"
                  style="color: #2c2c2c;"
                >
                  {{ getCSCenterPhoneNumber() }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-show="false"
            class="mt-0"
          >
            <v-col
              cols="12"
              class="px-4 px-sm-8 px-md-12"
            >
              <router-link
                :to="`//pf.kakao.com/${getKakaoChannelId()}`"
                target="_blank"
                class="text-decoration-none"
              >
                <v-btn
                  block
                  height="60px"
                  class="elevation-0"
                  style="border: 1px solid #CFC029; background: #FFF8B5;"
                >
                  <p
                    class="kakao-center-title my-0"
                    style="color: #2c2c2c ;"
                  >
                    {{ (!$vuetify.breakpoint.xsOnly) ? '고객센터 ' : '' }}카카오로 문의하기
                  </p>
                </v-btn>
              </router-link>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              class="text-center text-body-2 mt-4 mb-8"
              style="color: #FF0000;"
            >
              (링크는 12시간동안 유효합니다.)
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions
          class="pa-0"
        >
          <v-container
            fluid
            class="pa-0"
          >
            <v-row
              v-show="item.is_matched === 'apply'"
              no-gutters
            >
              <v-col cols="6">
                <v-btn
                  block
                  tile
                  height="60"
                  class="elevation-0 white--text"
                  color="#9E9E9E"
                  @click="rejectDialog = true"
                >
                  거절하기
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  tile
                  height="60"
                  class="elevation-0 white--text"
                  color="#007AFF"
                  @click="beforeAcceptDialog = true"
                >
                  수락하기
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-show="item.is_matched === 'cancel'"
              no-gutters
            >
              <v-col cols="12">
                <v-btn
                  block
                  tile
                  height="60"
                  class="elevation-0 white--text"
                  color="#9E9E9E"
                  disabled
                >
                  이미 거절하셨습니다
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-show="item.is_matched === 'done'"
              no-gutters
            >
              <v-col cols="12">
                <v-btn
                  block
                  tile
                  height="60"
                  class="elevation-0 white--text"
                  color="#9E9E9E"
                  disabled
                >
                  이미 수락하셨습니다
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-show="item.is_matched === 'hide'"
              no-gutters
            >
              <v-col cols="12">
                <v-btn
                  block
                  tile
                  height="60"
                  class="elevation-0 white--text"
                  color="#9E9E9E"
                  disabled
                >
                  삭제된 지원입니다
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rejectDialog"
      max-width="400px"
      overlay-color="#000000"
      overlay-opacity="0.8"
    >
      <v-card>
        <v-card-title>
          <!-- <span>지원내역 거절</span> -->
          <v-spacer />
        </v-card-title>
        <v-card-text class="black--text text-subtitle-1">
          <p>{{ item.basic_info.name | nameConv }} 간병사님</p>
          지원을 거절하시겠습니까?
        </v-card-text>
        <v-card-actions class="justify-end pa-8">
          <v-btn
            color=""
            class="elevation-0 grey lighten-2"
            @click="rejectDialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            @click="requestReject"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="beforeAcceptDialog"
      max-width="400px"
      overlay-color="#000000"
      overlay-opacity="0.8"
    >
      <v-card>
        <v-card-title>
          <!-- <span>지원내역 수락</span> -->
          <v-spacer />
        </v-card-title>
        <v-card-text class="black--text text-subtitle-1">
          <p>{{ item.basic_info.name | nameConv }} 간병사님</p>
          해당 간병인분과 전화해보셨나요?
        </v-card-text>
        <v-card-actions class="justify-end pa-8">
          <v-btn
            color=""
            class="elevation-0 grey lighten-2"
            @click="beforeAcceptDialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            @click="requestAccept"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="doneAcceptDialog"
      max-width="400px"
      overlay-color="#000000"
      overlay-opacity="0.8"
    >
      <v-card>
        <v-card-title>
          <!-- <span>지원내역 수락완료</span> -->
          <v-spacer />
        </v-card-title>
        <v-card-text class="black--text text-subtitle-1">
          <p>{{ item.basic_info.name | nameConv }} 간병사님</p>
          지원이 수락되었습니다.<br>
          등록된 간병인 요청하기 내역은 삭제됩니다.
        </v-card-text>
        <v-card-actions class="justify-end pa-8">
          <v-btn
            color="primary"
            class="elevation-0"
            @click="closeAndRedirect"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import nameConv from './model/filters'
import caregiverApi from './api/caregiver'
import { getConvertedAddressText } from '../../models/addressFinder'

export default {
  filters: {
    nameConv
  },
  data() {
    return {
      dialog: false,
      rejectDialog: false,
      beforeAcceptDialog: false,
      doneAcceptDialog: false,
      encStr: '',
      item: {
        basic_info: {
          idx: null,
          email: null,
          phone: null,
          password: null,
          name: null,
          age: null,
          gender: null
        },
        extra_info: {
          idx: null,
          license: null,
          career: null,
          desired_salary: null,
          office_hour: null,
          introduce: null,
          profile_image: null,
          working_area_idx: null,
          working_area_detail_idx: null,
          marketing_notification: null,
          created_at: null,
          updated_at: null
        },
        work_info: {
          number_of_completions: 0
        }
      },
      convertedAddress: ''
    }
  },
  async created() {
    const self = this
    this.$bus.$on('showRequestProfileDialog', (encStr) => {
      self.getCarer(encStr)
      self.encStr = encStr
      self.dialog = true
    })
    // self.dialog = true // for testing
  },
  methods: {
    getCSCenterPhoneNumber() {
      return this.$store.getters['App/appInfo'].callCenterNumber
    },
    getKakaoChannelId() {
      return this.$store.getters['App/appInfo'].kakaoChannel
    },
    getImageUrl(imageUrl) {
      const isRightUrl = /(https?:\/\/.*\.(?:png|jpg|jpeg))/i.test(imageUrl)
      return isRightUrl ? imageUrl : null
    },
    dial() {
      window.location = `tel: ${this.item.basic_info.phone}`
    },
    async getCarer(encStr) {
      const self = this
      try {
        this.item = await caregiverApi.getCarerByEncrypedString(encStr)
        this.convertedAddress = await getConvertedAddressText(
          this.item.extra_info.working_area_idx,
          this.item.extra_info.working_area_detail_idx
        )
      } catch (e) {
        if (e.status === 400) {
          if (e.data.detail === 'Out of time') {
            // eslint-disable-next-line no-alert
            alert('만료된 링크입니다')
            self.closeAndRedirect()
          }
        }
      }
    },
    async requestAccept() {
      const result = await caregiverApi.requestAccept(this.encStr)
      if (result) {
        this.beforeAcceptDialog = false
        this.doneAcceptDialog = true
        // eslint-disable-next-line no-alert
        // alert('수락되었습니다. 카카오톡 메세지를 확인해주세요')
        // this.dialog = false
        // window.location.replace('/') // 다음 다이얼로그로 pass
      }
    },
    async requestReject() {
      const result = await caregiverApi.requestReject(this.encStr)
      if (result) {
        // eslint-disable-next-line no-alert
        alert('지원을 거절하셨습니다.')
        this.closeAndRedirect()
      }
    },
    closeAndRedirect() {
      this.rejectDialog = false
      this.beforeAcceptDialog = false
      this.doneAcceptDialog = false
      this.dialog = false
      window.location.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 0px !important;
  // border-top-left-radius: 10px !important;
  // border-bottom-left-radius: 10px !important;
  // border-top-right-radius: 0 !important;
  // border-bottom-right-radius: 0 !important;
}
.bottom-card-action {
  height: 60px !important;
  background: #007AFF;
}
.call-center-title {
  background: url('../_common/assets/icon-call-black.png') center left no-repeat;
  background-size: 24px 24px;
  padding-left: 30px;
}

.profileCardLabel {
  color: #9E9E9E;
  min-width: 120px;
}

.text-label-contact-guide {
  color: #025BBC;
}

.label-key {
  font-weight: 500 !important;
  padding-right: 8px;
}
.label-value {
  font-weight: 400 !important;
}

.kakao-center-title  {
  background: url('../_common/assets/icon-kakao_150x.png') center left no-repeat;
  background-size: 20px;
  padding-left: 30px;
  padding-bottom: 2px;
}
.left-icon-age {
  background: url('./assets/icon_cake.png') left center no-repeat;
  background-size: 22px;
  padding-left: 26px;
}
.left-icon-gender {
  background: url('./assets/icon_gender.png') left center no-repeat;
  background-size: 22px;
  padding-left: 26px;
}
.left-icon-carrer {
  background: url('./assets/icon_carrer.png') left center no-repeat;
  background-size: 22px;
  padding-left: 26px;
}
img.mobile {
  width: 48px !important;
  height: 48px !important;
}
</style>
