/**
 * 포맷 사이즈 리턴
 *
 * @param {Number} size Input number
 * @return {String} 파일 크기에 따라 처리된 스트링이 붙음
 */
export default function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`
  } if (size > 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`
  } if (size > 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(2)} MB`
  } if (size > 1024) {
    return `${(size / 1024).toFixed(2)} KB`
  }
  return `${size.toString()} B`
}
