export function getGenderConv(gender) {
  let convertedGender = 'Unknown'
  if (gender === 'male') {
    convertedGender = '남성'
  } else if (gender === 'female') {
    convertedGender = '여성'
  }
  return convertedGender
}

export function getOfficeHourConv(officeHour) {
  const items = {
    anytime: '무관',
    allday: '24시간',
    daytime: '주간 12시간',
    nighttime: '야간 12시간'
  }
  return items[officeHour] || '-'
}

export function addConvertedItem(item = {}) {
  const newItem = item
  if (item.basic_info) {
    newItem.basic_info.gender_conv = '-'
    if (item.basic_info.gender) {
      newItem.basic_info.gender_conv = getGenderConv(item.basic_info.gender)
    }
  }
  if (item.extra_info) {
    newItem.extra_info.office_hour_conv = '-'
    if (item.extra_info.office_hour) {
      newItem.extra_info.office_hour_conv = getOfficeHourConv(item.extra_info.office_hour)
    }
  }
  return newItem
}
