<template>
  <div>
    <v-bottom-navigation
      v-show="this.$route.path !== '/request'"
      fixed
      grow
      height="60"
      style="margin-bottom: 80px;"
    >
      <v-btn
        block
        depressed
        color="#007AFF"
        height="60px"
        @click="$router.push('/request').catch(() => {})"
      >
        <div class="d-flex justify-center align-center">
          <img
            :src="require(`./assets/btn_my.png`)"
            style="width: 19px; display: inline-flex;"
          >
          <span
            style="color: #FFF;"
            class="text-body-1 text-weight-bold pl-2"
          >간병인 요청하기</span>
        </div>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      fixed
      grow
      height="80"
      class="elevation-0"
    >
      <v-btn
        class="navBtn"
        :class="{'activate': (this.$route.path === '/')}"
        @click="$router.push('/').catch(() => {})"
      >
        <div
          class="pt-2"
        >
          홈
        </div>
        <img
          :src="require(`./assets/btn_home${(this.$route.path === '/') ? '_active' : ''}.png`)"
        >
      </v-btn>

      <v-btn
        class="navBtn"
        :class="{'activate': (this.$route.path === '#')}"
        @click="dial"
      >
        <div
          class="pt-2"
        >
          전화문의
        </div>
        <img
          :src="require(`./assets/btn_phone${(this.$route.path === '/') ? '_active' : ''}.png`)"
        >
      </v-btn>

      <v-btn
        class="navBtn"
        :class="{'activate': (this.$route.path === '#')}"
        @click="kakaoPage"
      >
        <div
          class="pt-2"
        >
          카톡문의
        </div>
        <img
          :src="require(`./assets/btn_kakao${(this.$route.path === '/') ? '_active' : ''}.png`)"
        >
      </v-btn>

      <v-btn
        class="navBtn"
        :class="{'activate': (this.$route.path === '/findCaregiver')}"
        @click="$router.push('/findCaregiver').catch(() => {})"
      >
        <div
          class="pt-2"
        >
          일자리찾기
        </div>
        <img
          :src="require(`./assets/btn_search${(this.$route.path === '/findCaregiver') ? '_active' : ''}.png`)"
        >
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
// import menus from './menus'

export default {
  data() {
    return {
      contactBottomSheet: false
    }
  },
  methods: {
    goPage(obj) {
      this.$router.push(obj.path).catch(() => {})
    },
    dial() {
      const phoneNumber = this.$store.getters['App/appInfo'].callCenterNumber
      window.location = `tel: ${phoneNumber}`
    },
    kakaoPage() {
      const kakaoPageUrl = `//pf.kakao.com/${this.$store.getters['App/appInfo'].kakaoChannel}`
      window.open(kakaoPageUrl, '_blank')
    },
    viewContactModal() {
      this.$bus.$emit('showContactCenterDialog', 'withKakao')
    }
  }
}
</script>

<style lang="scss" scoped>
.navBtn {
  div {
    color: #9E9E9E;
  }
  img {
    width: 24px;
    height: 24px;
  }
}
.navBtn.activate {
  div {
    color: #2C2C2C;
  }
}
</style>
