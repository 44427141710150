/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/**
 * 콤마 처리후 리턴
 *
 * @param {Number} n Input number
 * @param {Number} decimals 소수점 단위
 * @return {String} 처리된 포맷
 */
export default function (n, decimals) {
  if (typeof decimals === 'undefined') {
    decimals = 0
  }

  let n2 = Number(String(n).replace(/[^-+.\d]+/g, ''))
  if (isNaN(n2)) {
    return n
    // throw new Error('Input must be a number')
  }

  n2 = n2.toFixed(decimals)
  if (decimals === 0) {
    return n2.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
  }
  const n3 = String(n).split('.')
  return `${n3[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')}.${n3[1]}`
}
