function nameConv(value) {
  if (!value) return ''
  const valueArr = value.split('')
  const strLen = valueArr.length

  if (strLen === 2) {
    valueArr[1] = 'O'
  } else if (strLen >= 3) {
    for (let i = 1; i <= strLen - 2; i += 1) {
      valueArr[i] = 'O'
    }
  }
  return valueArr.join('')
}

export default nameConv
