/* eslint-disable no-param-reassign */
function fillZero(d) {
  return (d < 10 ? '0' : '') + d
}

/**
 * Return to Date
 *
 * @param {Any} dt datetime
 * @param {String} retType return division type
 * @return {String} date
 */
/* eslint no-param-reassign: "error" */
export default function (dt, retType = '-') {
  let ret = ''
  let df = ['-', '-', '']

  if (retType.toLowerCase() === 'ko') {
    df = ['년 ', '월 ', '일']
  }

  if (typeof dt === 'number') {
    // for number
    dt = new Date(dt * 1000)
    ret = `${dt.getFullYear()}${df[0]}${fillZero(dt.getMonth() + 1)}${df[1]}${fillZero(dt.getDate())}${df[2]}`
  } else if (typeof dt === 'string') {
    // for sring (ex '2000-01-01 00:00:00')
    dt = dt.split(/[^0-9]/)
    ret = `${dt[0]}${df[0]}${dt[1]}${df[1]}${dt[2]}${df[2]}`
  }
  return ret
}
