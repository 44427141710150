/**
 * 대쉬(-) 처리후 리턴
 *
 * @param {Number} n Input number
 * @return {String} 처리된 포맷
 */
export default function (n) {
  if (!n || n === undefined || n === null) {
    return ''
  }
  return n.toString().replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
}
