<template>
  <div>
    <div
      v-for="(item, k) in items"
      :key="`popup-${item.idx}`"
    >
      <PopupDialog
        v-model="items[k]"
        :cursor="k"
        :is-display="false"
        @closeDialog="onCloseDialog"
      />
      <!-- <PopupDialog v-model="item" /> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Api from './api'
import PopupDialog from './PopupDialog'

export default {
  components: {
    PopupDialog
  },
  data() {
    return {
      currentCursor: -1,
      items: [],
      openItems: [],
      alreadyShownItems: []
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    async setup() {
      if (!this.$route.query.ENC_STR) {
        await this.getItems()
      }
    },
    async getItems() {
      const items = await Api.getPromotionPopup()
      this.items = items
      this.items.forEach((item, k) => {
        this.openItems[k] = false
        this.alreadyShownItems[k] = (this.$cookies.get(`popup-${item.idx}`) === 'alreadyShown')
      })
      let onceTrueFlag = false
      this.openItems.forEach((item, k) => {
        if (this.openItems[k] === false && this.alreadyShownItems[k] === false && onceTrueFlag === false) {
          this.openItems[k] = true
          onceTrueFlag = true
        }
      })
    },
    onCloseDialog(cursor) {
      this.openItems[cursor] = false
      try {
        if (
          (cursor + 1) <= this.openItems.length
          && this.openItems[(cursor + 1)] === false
          && this.$cookies.get(`popup-${this.items[(cursor + 1)].idx}`) !== 'alreadyShown'
        ) {
          this.openItems[(cursor + 1)] = true
          this.items = _.cloneDeep(this.items)
        }
      } catch (e) {
        // console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
