<template>
  <DefaultLayout />
</template>
<script>
import DefaultLayout from './modules/layouts/default/Layout'

export default {
  name: 'App',

  components: {
    DefaultLayout
  }
}
</script>
