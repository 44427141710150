<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="100%"
      max-width="500"
      overlay-color="#000000"
      overlay-opacity="0.8"
    >
      <v-card>
        <v-container>
          <v-row
            justify="end"
            align="center"
          >
            <v-col cols="auto">
              <v-btn
                icon
                @click="dialog=false"
              >
                <v-icon>
                  close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              <img
                :src="require('./assets/logo-full@3x.png')"
                style="width: 120px;"
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-8 text-center">
              아래로 문의 주시면<br>
              저희 직원이 친절하게 안내해 드리겠습니다.
            </v-col>
          </v-row>
          <v-row
            v-show="dialogType === 'withKakao'"
            no-gutters
          >
            <v-col
              cols="12"
              class="px-8 pt-4"
            >
              <v-btn
                block
                color="#2C2C2C"
                height="60px"
                class="elevation-0"
                :href="`tel:${getCSCenterPhoneNumber()}`"
              >
                <p
                  v-show="!$vuetify.breakpoint.xsOnly"
                  class="call-center-title my-0 white--text"
                >
                  고객센터
                </p>
                <div
                  v-show="!$vuetify.breakpoint.xsOnly"
                  class="white--text px-4"
                >
                  |
                </div>
                <div
                  class="white--text"
                  :class="{ 'call-center-title': $vuetify.breakpoint.xsOnly }"
                >
                  {{ getCSCenterPhoneNumber() }}
                </div>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="mb-12 px-8 pt-4 "
            >
              <router-link
                :to="`//pf.kakao.com/${getKakaoChannelId()}`"
                target="_blank"
                class="text-decoration-none"
              >
                <v-btn
                  block
                  color="#FAE300"
                  height="60px"
                  class="elevation-0"
                >
                  <p
                    class="kakao-center-title my-0"
                    style="color: #2C2C2C;"
                  >
                    {{ (!$vuetify.breakpoint.xsOnly) ? '고객센터 ' : '' }}카카오로 문의하기
                  </p>
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions
          v-show="dialogType === 'default'"
          class="bottom-card-action"
        >
          <v-spacer />
          <div>
            <p class="call-center-title my-0 white--text">
              고객센터
            </p>
          </div>
          <div class="white--text px-4">
            |
          </div>
          <div>
            <a
              :href="`tel:${getCSCenterPhoneNumber()}`"
              class="white--text "
            >{{ getCSCenterPhoneNumber() }}</a>
          </div>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      dialogType: 'default'
    }
  },
  created() {
    const self = this
    this.$bus.$on('showContactCenterDialog', (dialogType) => {
      // dialogType is 'default' or 'withKakao'
      self.dialogType = (dialogType === 'withKakao') ? dialogType : 'default'
      self.dialog = true
    })
    // self.dialog = true  // for testing
  },
  methods: {
    getCSCenterPhoneNumber() {
      return this.$store.getters['App/appInfo'].callCenterNumber
    },
    getKakaoChannelId() {
      return this.$store.getters['App/appInfo'].kakaoChannel
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 10px !important;
}
.bottom-card-action {
  height: 60px !important;
  background: #007AFF;
}
.call-center-title {
  background: url('./assets/icon-call.png') center left no-repeat;
  background-size: 24px 24px;
  padding-left: 30px;
}
.kakao-center-title  {
  background: url('./assets/icon-kakao_150x.png') center left no-repeat;
  background-size: 20px 20px;
  padding-left: 30px;
}
</style>
