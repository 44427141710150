<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="100%"
      max-width="600"
      overlay-color="#000000"
      overlay-opacity="0.8"
    >
      <v-card>
        <v-container>
          <v-row
            justify="end"
            align="center"
          >
            <v-col cols="auto">
              <v-btn
                icon
                @click="dialog=false"
              >
                <v-icon>
                  close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            align="center"
            justify="center"
            style=""
          >
            <v-col
              cols="3"
              class="pl-4 pl-sm-8 pl-md-12"
            >
              <!-- :class="{'mobile': ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)}" -->
              <img
                :src="getImageUrl(item.extra_info.profile_image) || require(`./assets/illust_${(item.basic_info.gender === 'male') ? 'man' : 'woman'}.png`)"
                style="max-width: 100%; min-width: 50px; max-height: 100px; witdh:100%; border-radius: 50%;"
              >
            </v-col>
            <v-col>
              <v-container class="pl-0 pl-sm-2 py-0">
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0 pb-sm-2"
                  >
                    <span class="text-subtitle-1 font-weight-bold mr-2">{{ item.basic_info.name | nameConv }}</span>
                    <span class="text-body-2">간병사님</span>
                  </v-col>
                </v-row>
                <v-row
                  v-show="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                  class="mt-0"
                >
                  <v-col
                    cols="auto"
                    class=""
                  >
                    <div class="left-icon-age">
                      <span class="label-key">나이</span>
                      <span class="label-value">{{ item.basic_info.age }}세</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="auto"
                    class=""
                  >
                    <div class="left-icon-gender">
                      <span class="label-key">성별</span>
                      <span class="label-value">{{ item.basic_info.gender_conv }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="auto"
                    class=""
                  >
                    <div class="left-icon-carrer">
                      <span class="label-key">경력</span>
                      <span class="label-value">{{ item.extra_info.career }}년</span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row
            v-show="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
            no-gutters
            align="center"
            justify="space-between"
            justify-sm="start"
            class="pt-4 pl-1"
          >
            <v-col
              cols="auto"
              class="pl-sm-4 pr-sm-6"
            >
              <div class="left-icon-age">
                <span
                  v-show="$vuetify.breakpoint.sm"
                  class="label-key"
                >나이</span>
                <span class="label-value">{{ item.basic_info.age }}세</span>
              </div>
            </v-col>
            <v-col
              cols="auto"
              class="px-0 px-sm-6"
            >
              <div class="left-icon-gender">
                <span
                  v-show="$vuetify.breakpoint.sm"
                  class="label-key"
                >성별</span>
                <span class="label-value">{{ item.basic_info.gender_conv }}</span>
              </div>
            </v-col>
            <v-col
              cols="auto"
              class="pr-4 pl-sm-6 pr-sm-4"
            >
              <div class="left-icon-carrer">
                <span
                  v-show="$vuetify.breakpoint.sm"
                  class="label-key"
                >경력</span>
                <span class="label-value">{{ item.extra_info.career }}년</span>
              </div>
            </v-col>
          </v-row>
          <v-row
            style="border-top: 1px solid #CCC; margin-top: 28px; padding-top: 18px;"
          >
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              자격증
            </v-col>
            <v-col>
              {{ item.extra_info.license || '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              지역
            </v-col>
            <v-col>
              {{ convertedAddress || '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              근무시간
            </v-col>
            <v-col>
              {{ item.extra_info.office_hour_conv }}
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              희망급여
            </v-col>
            <v-col>
              {{ item.extra_info.desired_salary }}
            </v-col>
          </v-row> -->
          <v-row>
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              근무내역
            </v-col>
            <v-col>
              {{ $options.filters.numberFormat(item.work_info.number_of_completions) }}회
            </v-col>
          </v-row>
          <v-row class="pb-8">
            <v-col
              cols="auto"
              class="pl-4 pl-sm-8 pl-md-12 profileCardLabel"
            >
              자기소개
            </v-col>
            <v-col>
              {{ item.extra_info.introduce }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="text-center text-label-contact-guide text-body-2"
            >
              아래로 문의 주시면 저희 직원이 연결시켜 드리겠습니다.
            </v-col>
          </v-row>
          <v-row class="pb-0">
            <v-col
              cols="12"
              class="px-4 px-sm-8 px-md-12"
            >
              <v-btn
                block
                height="60px"
                class="elevation-0"
                style="border: 1px solid #9e9e9e; background: #f9f9f9;"
                :href="`tel:${getCSCenterPhoneNumber()}`"
              >
                <p
                  v-show="!$vuetify.breakpoint.xsOnly"
                  class="call-center-title my-0"
                  style="color: #2c2c2c;"
                >
                  고객센터
                </p>
                <div
                  v-show="!$vuetify.breakpoint.xsOnly"
                  class="px-4"
                  style="color: #2c2c2c;"
                >
                  |
                </div>
                <div
                  :class="{ 'call-center-title': $vuetify.breakpoint.xsOnly }"
                  style="color: #2c2c2c;"
                >
                  {{ getCSCenterPhoneNumber() }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-0 pb-6">
            <v-col
              cols="12"
              class="px-4 px-sm-8 px-md-12"
            >
              <router-link
                :to="`//pf.kakao.com/${getKakaoChannelId()}`"
                target="_blank"
                class="text-decoration-none"
              >
                <v-btn
                  block
                  height="60px"
                  class="elevation-0"
                  style="border: 1px solid #CFC029; background: #FFF8B5;"
                >
                  <p
                    class="kakao-center-title my-0"
                    style="color: #2c2c2c ;"
                  >
                    {{ (!$vuetify.breakpoint.xsOnly) ? '고객센터 ' : '' }}카카오로 문의하기
                  </p>
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import nameConv from './model/filters'
import caregiverApi from './api/caregiver'
import { getConvertedAddressText } from '../../models/addressFinder'

export default {
  filters: {
    nameConv
  },
  data() {
    return {
      dialog: false,
      item: {
        basic_info: {
          idx: null,
          email: null,
          phone: null,
          password: null,
          name: null,
          age: null,
          gender: null
        },
        extra_info: {
          idx: null,
          license: null,
          career: null,
          desired_salary: null,
          office_hour: null,
          introduce: null,
          profile_image: null,
          working_area_idx: null,
          working_area_detail_idx: null,
          marketing_notification: null,
          created_at: null,
          updated_at: null
        },
        work_info: {
          number_of_completions: 0
        }
      },
      convertedAddress: ''
    }
  },
  async created() {
    const self = this
    this.$bus.$on('showCarerDialog', (extraIdx) => {
      self.getCarer(extraIdx)
      self.dialog = true
    })
    // self.dialog = true // for testing
  },
  methods: {
    getCSCenterPhoneNumber() {
      return this.$store.getters['App/appInfo'].callCenterNumber
    },
    getKakaoChannelId() {
      return this.$store.getters['App/appInfo'].kakaoChannel
    },
    getImageUrl(imageUrl) {
      const isRightUrl = /(https?:\/\/.*\.(?:png|jpg|jpeg))/i.test(imageUrl)
      return isRightUrl ? imageUrl : null
    },
    async getCarer(idx) {
      this.item = await caregiverApi.getCaregiver(idx)
      this.convertedAddress = await getConvertedAddressText(
        this.item.extra_info.working_area_idx,
        this.item.extra_info.working_area_detail_idx
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 10px !important;
}
.bottom-card-action {
  height: 60px !important;
  background: #007AFF;
}
.call-center-title {
  background: url('../_common/assets/icon-call-black.png') center left no-repeat;
  background-size: 24px 24px;
  padding-left: 30px;
}

.profileCardLabel {
  color: #9E9E9E;
  min-width: 120px;
}

.text-label-contact-guide {
  color: #025BBC;
}

.label-key {
  font-weight: 500 !important;
  padding-right: 8px;
}
.label-value {
  font-weight: 400 !important;
}

.kakao-center-title  {
  background: url('../_common/assets/icon-kakao_150x.png') center left no-repeat;
  background-size: 20px;
  padding-left: 30px;
  padding-bottom: 2px;
}
.left-icon-age {
  background: url('./assets/icon_cake.png') left center no-repeat;
  background-size: 22px;
  padding-left: 26px;
}
.left-icon-gender {
  background: url('./assets/icon_gender.png') left 60% no-repeat;
  background-size: 22px;
  padding-left: 26px;
}
.left-icon-carrer {
  background: url('./assets/icon_carrer.png') left bottom no-repeat;
  background-size: 20px;
  padding-left: 26px;
}
img.mobile {
  width: 48px !important;
  height: 48px !important;
}
</style>
