import { Api } from '@/libraries/api'

async function getPromotionPopup() {
  const url = `/promotion/type/popup`
  const result = await Api().get(url)
  const { items } = result.data.data
  return items
}

async function getPromotionBanner() {
  const url = `/promotion/type/banner`
  const result = await Api().get(url)
  const { items } = result.data.data
  return items
}

export default {
  getPromotionBanner,
  getPromotionPopup
}
