const NAMESPACE = 'App'

const App = {
  language: 'ko',
  currentTheme: 'light',
  currentManufacturer: {
    idx: 0,
    name: ''
  },
  routerHistoryStack: [],
  info: {
    callCenterNumber: '',
    callCenterNumberForPayments: '',
    kakaoChannel: '',
    iosAppUrl: '',
    androidAppUrl: ''
  }
}

const routerHistoryStackLimit = 3

export default {
  namespace: NAMESPACE,
  namespaced: true,
  state: App,
  mutations: {
    init(state) {
      state.language = App.language
      // state.currentTheme = App.currentTheme
      state.currentManufacturer = { ...App.currentManufacturer }
      state.info = { ...App.info }
    },
    updateLanguage(state, language) {
      state.language = language || 'ko'
    },
    updateTheme(state, theme) {
      state.currentTheme = theme || 'light'
      console.log(state.currentTheme)
    },
    updateManufacturer(state, { idx, name }) {
      state.currentManufacturer.idx = idx
      state.currentManufacturer.name = name
    },
    updateRouterHistory(state, data) {
      while (state.routerHistoryStack.length >= routerHistoryStackLimit) {
        state.routerHistoryStack.pop()
      }
      state.routerHistoryStack.unshift({
        name: data.name,
        path: data.path,
        fullPath: data.fullPath
      })
    },
    updateAppInfo(state, data) {
      state.info = { ...data }
    }
  },
  actions: {
    initialize({ commit }) {
      commit('init')
    },
    changeLanguage({ commit }, language) {
      commit('updateLanguage', language)
    },
    changeTheme({ commit }, theme) {
      commit('updateTheme', theme)
    },
    changeManufacturer({ commit }, { idx, name }) {
      commit('updateManufacturer', { idx, name })
    },
    addRouterHistory({ commit }, data) {
      commit('updateRouterHistory', data)
    },
    AppInfo({ commit }, data) {
      commit('updateAppInfo', data)
    }
  },
  getters: {
    currentLanguage(state) {
      return state.language
    },
    currentTheme(state) {
      return state.currentTheme
    },
    currentManufacturer(state) {
      return state.currentManufacturer
    },
    routerHistory(state) {
      return state.routerHistoryStack
    },
    routerHistoryLatest(state) {
      return state.routerHistoryStack[0]
    },
    appInfo(state) {
      return state.info
    }
  }
}
