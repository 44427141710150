import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ko from 'vuetify/lib/locale/ko'
// import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: '#21CFF3',
        accent: '#FF4081',
        secondary: '#ffe18d',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      light: {
        primary: '#24262D',
        accent: '#e91e63',
        secondary: '#30b1dc',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      }
    }
  },
  lang: {
    locales: { ko },
    current: 'ko'
  },
  icons: {
    iconfont: 'google-material'
  }
})

vuetify.isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  }
  return false
}

export default vuetify
