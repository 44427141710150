// https://github.com/cmp-cc/vue-cookies
import Vue from 'vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

// set default config
Vue.$cookies.config('30d')

// set global cookie
// Vue.$cookies.set('theme','default')
// Vue.$cookies.set('hover-time','1s')
