import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/findCaregiver',
    name: 'FindCaregiver.Index',
    component: importPage('findCaregiver/Main'),
    meta: {
      title: '간병일자리 찾기',
      scrollToTop: true
    }
  }
]
