import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/account/signin',
    name: 'Account.SignIn',
    component: importPage('account/SignIn')
  },
  {
    path: '/account/signup',
    name: 'Account.SignUp',
    component: importPage('account/SignUp')
  }
]
