/* eslint-disable no-restricted-syntax */
import Vue from 'vue'

import * as numberFilters from './number'
import * as stringFilters from './string'
import * as currencyFilters from './currency'
import * as datetimeFilters from './datetime'

const VueFilters = {
  // eslint-disable-next-line no-shadow
  install(Vue) {
    const filters = [numberFilters, stringFilters, currencyFilters, datetimeFilters]
    for (const o of filters) {
      for (const key in o.default) {
        if (Object.prototype.hasOwnProperty.call(o.default, key)) {
          // @ts-ignore
          Vue.filter(key, o.default[key])
        }
      }
    }
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  // @ts-ignore
  window.Vue.use(VueFilters)
}
// @ts-ignore
Vue.use(VueFilters)
