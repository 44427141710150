<template>
  <div
    v-show="$vuetify.breakpoint.lgAndUp"
    id="floatingNavWrap"
    :class="{'isStop' : isStop}"
  >
    <div>
      <a
        :href="`//pf.kakao.com/${getKakaoChannelId()}`"
        target="_blank"
      ><img :src="require('./assets/f_btn_kakao.png')"></a>
    </div>
    <div><a :href="`tel:${getCSCenterPhoneNumber()}`"><img :src="require('./assets/f_btn_call.png')"></a></div>
    <div>
      <img
        :src="require('./assets/f_btn_top.png')"
        @click="top"
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isStop: false,
      footerHeight: null
    }
  },
  created() {
    // this.setFooterHeight()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getCSCenterPhoneNumber() {
      return this.$store.getters['App/appInfo'].callCenterNumber
    },
    getKakaoChannelId() {
      return this.$store.getters['App/appInfo'].kakaoChannel
    },
    top() {
      window.scrollTo(0, 0)
    },
    // setFooterHeight() {
    //   console.log(this.$refs['footer-wrap'].scrollHeight)
    // },
    handleScroll() {
      // console.log(event)
      // console.log(window.top.scrollY)
      this.isStop = (window.top.scrollY >= 1300)
      // console.log(this.isStop)
    }
  }
}
</script>

<style lang="scss" scoped>
#floatingNavWrap {
  position: fixed;
  top: 240px;
  right: 5%;
  display: flex;
  flex-direction: column;
  div, div > a {
    cursor: pointer;
    width: 114px;
    margin-top: -1px;
    img {
      width: inherit;
    }
  }
  div:nth-child(1),
  div:nth-child(2) {
    height: 114px;
  }
  div:nth-child(3) {
    height: 65px;
  }
}
// .isStop {
//   bottom: 100px;
// }
</style>
