/* eslint-disable no-param-reassign */
/**
 * relativeDate
 * 날짜 표시 로컬에서의 현재 비교 날짜 변환 함수
 * 1시간 이내 기사 : 경과한 분 표시 ( ex) 16분전 )
 * 1시간 이후 ~ 24시간 이내 기사 : 경과한 시간 표시 ( ex) 3시간전 )
 * 24시간 이후 & 올해 기사 : 날짜 + 시간 표시 ( ex)8월 23일 오후 3:32분 )
 * 24시간 이후 & 작년 이전 기사 : 연도 + 날짜 + 시간 표시 ( ex) 2014년 8월 23일 오후 3:32분 )
 *
 * @param {String} dt 해당 datetime format string
 * @return {string} 현재 시간 기준 변환된 날짜
 */
import toDateTime from './toDateTime'

export default function relativeDate(dt) {
  if (typeof dt === 'number') {
    dt = toDateTime(dt)
  }

  const d = dt.split(/[^0-9]/)
  const Second = {
    sec: 1, min: 60, hour: 3600, day: 86400, month: 2592000, year: 31536000
  }
  const td = new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5])
  const now = new Date()
  const nd = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours() + 9,
    now.getUTCMinutes(),
    now.getUTCSeconds()
  )
  let rs = ''

  if (td.getTime() - (nd.getTime() - Second.hour * 1000) > 0) {
    const min = Math.floor(60 - (td.getTime() - (nd.getTime() - Second.hour * 1000)) / 1000 / 60)
    if (min < 5) {
      rs = '조금 전'
    } else {
      rs = `${min}분 전`
    }
  } else if (nd.getTime() - td.getTime() < Second.day * 1000) {
    const tmpHour = Math.floor((nd.getTime() - td.getTime()) / 1000 / Second.hour)
    rs = `${tmpHour}시간 전`
  } else {
    if (nd.getFullYear() > td.getFullYear()) {
      rs = `${td.getFullYear()}년 `
    }
    rs += `${td.getMonth() + 1}월 ${td.getDate()}일`

    // rs = ((nd.getFullYear() > td.getFullYear()) ? td.getFullYear() + '년 ' : '') +
    //   (td.getMonth() + 1) + '월 ' +
    //   td.getDate() + '일 '
    // + ['오전', '오후'][Math.floor(td.getHours() / 12)]
    // + ' '
    // + ('0' + td.getHours() % 12).substr(-2, 2) + ':'
    // + ('0' + td.getMinutes()).substr(-2, 2)
  }
  return rs
}
