<template>
  <div class="footer-wrap">
    <footer>
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-container>
              <v-row justify="center" justify-sm="start">
                <v-col cols="6" sm="auto" align-self="center" class="white--text text-center">
                  <!-- :class="{'mx-auto': $vuetify.breakpoint.xs}" -->
                  <router-link to="/terms/terms_of_service" class="footer-link"> 서비스 이용약관 </router-link>
                </v-col>
                <v-col cols="6" sm="auto" align-self="center" class="white--text text-center pl-sm-16">
                  <router-link to="/terms/terms_of_privacy" class="footer-link"> 개인정보취급방침 </router-link>
                  <!-- <span>서비스 이용약관</span>
                  <span class="pl-16">개인정보취급방침</span> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <div>
        <v-divider dark color="#3F3F3F" />
      </div>
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-container>
              <v-row>
                <v-col class="mt-16">
                  <v-img :src="require('./assets/logo-dark-full@3x.png')" width="66" height="26" />
                </v-col>
              </v-row>
              <v-row>
                <v-col style="color: #c4c4c4; font-size: 14px" cols="12" md="7" order="1" order-sm="1">
                  <span class="font-weight-bold">제공 서비스 : 헬퍼 ㅣ 회사명 : (주)디스피릿</span><br />
                  사업장 주소지 : 전북 전주시 덕진구 백제대로 563-1 3층<br />
                  대표자 명 : 이도언 ㅣ 개인정보관리책임자 : 이도언 ㅣ 사업자등록번호 : 655-87-01867 ㅣ 통신판매업신고 :
                  제2020-서울종로-2182호 | 직업정보제공사업번호 : 서울청 제2020-26호<br />
                  고객센터 전화문의 운영시간 : 월~금(오전9시~오후6시) 주말 및 공휴일 휴무<br />
                  카카오문의 운영시간 : 월~금(오전9시~18시 30분) 주말 및 공휴일 휴무<br />
                  &copy; {{ new Date().getFullYear() }} 디스피릿. All Rights Reserved.<br />
                </v-col>
                <v-col class="d-none d-md-flex" cols="auto" order="2" order-sm="2">
                  <v-divider dark color="#3F3F3F" vertical />
                </v-col>
                <v-col order="4" order-sm="3" class="text-center text-sm-left mb-16 mb-sm-0">
                  <div class="my-4 pb-4">
                    <span style="color: #c4c4c4" class="font-weight-bold">간병인 전용 앱 다운로드 </span>
                  </div>
                  <v-container fluid class="pa-0">
                    <v-row justify="center" justify-sm="start">
                      <v-col cols="auto" sm="auto" xs="auto" class="py-0 px-2">
                        <router-link
                          :to="$store.getters['App/appInfo'].androidAppUrl.replace('https:', '')"
                          target="_blank"
                          class="flex-1"
                        >
                          <v-img
                            :src="require('./assets/app-link-google-play.png')"
                            width="172"
                            height="50"
                            style="display: inline-flex"
                          />
                        </router-link>
                      </v-col>
                      <v-col cols="auto" xs="auto" sm="auto" class="py-0 px-2">
                        <router-link
                          :to="$store.getters['App/appInfo'].iosAppUrl.replace('https:', '')"
                          target="_blank"
                        >
                          <v-img
                            :src="require('./assets/app-link-app-store.png')"
                            width="172"
                            height="50"
                            style="display: inline-flex"
                          />
                        </router-link>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" order="3" order-sm="3">
                  <div style="background: #3f3f3f; padding: 24px; color: #c4c4c4">
                    (주)디스피릿은 통신판매중개자로서 거래에 필요한 시스템을 운영 및 제공합니다. <br />
                    환자 혹은 보호자와 간병인 사이에 발생 분쟁에 대한 책임은 환자 혹은 보호자와 간병인에게 있습니다.
                    <br />
                    따라서 회사는 제공하는 서비스에 대하여 어떠한 책임도 없습니다. 다만 원만한 분쟁 해결을 위해
                    중재자로서 최선을 다하겠습니다.
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </div>
</template>

<script>
import menus from "./menus"

export default {
  data() {
    return {
      menuItems: menus
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-wrap {
  background: #2c2c2c;
  // padding-top: 120px;
  padding-bottom: 100px;
}

a.footer-link,
a.footer-link:visited {
  color: #ffffff;
  text-decoration: none;
}
a.footer-link:hover,
a.footer-link:focus {
  color: #ffffff;
  font-weight: bolder;
  text-decoration: underline;
}
</style>
