/**
 * @description nl2br function.
 * @example
 * ```
 * {{ 'this is a big city!' | truncate(12) }} => 'this is a bi...`
 * {{ 'this is a big city!' | truncate(12, '~~~') }} => 'this is a bi~~~`
 * ```
 */
export default function (str) {
  return str.replace(/([\r\n|\r|\n]){1,}/g, '<br />')
}
