import dayjs from 'dayjs'

const NAMESPACE = 'Account'

const Account = {
  isSignedIn: false,
  auth: {
    token: '',
    refreshToken: '',
    expiredAt: null
  },
  user: {
    app_idx: 0,
    idx: 0,
    user_id: '',
    mail: '',
    mail_verified: false,
    is_deactivated: false,
    created_at: '',
    updated_at: ''

  },
  userExtra: {
    idx: 0,
    user_idx: 0,
    real_name: '',
    nickname: '',
    sign_up_with: '',
    extra: {},
    privileges: {}
  }
}

export default {
  namespace: NAMESPACE,
  namespaced: true,
  state: Account,
  mutations: {
    init(state) {
      state.isSignedIn = false
      state.auth = { ...Account.auth }
      state.user = { ...Account.user }
      state.userExtra = { ...Account.userExtra }
    },
    updateToken(state, token) {
      state.auth.token = token
      state.isSignedIn = Boolean(token)
    },
    updateRefreshToken(state, token) {
      state.auth.refreshToken = token
    },
    updateExpiredAt(state, expireIn) {
      if (expireIn) {
        const now = dayjs()
        const addExpireDate = now.add(expireIn, 'm')
        state.auth.expiredAt = addExpireDate
      }
    },
    updateUser(state, data) {
      state.user = { ...data }
    },
    updateUserExtra(state, data) {
      state.userExtra = { ...data }
    },
    updatePrivileges(state, data) {
      state.privileges = { ...data }
    }
  },
  actions: {
    initialize({ commit }) {
      commit('init')
    },
    setToken({ commit }, token) {
      commit('updateToken', token)
    },
    setRefreshToken({ commit }, refreshToken) {
      commit('updateRefreshToken', refreshToken)
    },
    setExpiredAt({ commit }, expireIn) {
      commit('updateExpiredAt', expireIn)
    },
    setUser({ commit }, data) {
      commit('updateUser', data)
    },
    setUserExtra({ commit }, data) {
      commit('updateUserExtra', data)
    },
    setPrivileges({ commit }, data) {
      commit('updatePrivileges', data)
    }
  },
  getters: {
    isSignedIn(state) {
      return state.isSignedIn
    },
    isAdmin(state) {
      return !!state.userExtra.privileges.is_admin
    },
    isManufacturer(state) {
      return !!state.userExtra.privileges.is_manufacturer
    },
    getToken(state) {
      return state.auth.token
    },
    getRefreshToken(state) {
      return state.auth.refreshToken
    },
    getUser(state) {
      return state.user
    },
    getUserExtra(state) {
      return state.userExtra
    },
    getPrivileges(state) {
      return state.privileges
    }
  }
}
