import { Api, makeGetUrl } from '@/libraries/api'
import { addConvertedItem } from './converter'

async function getCaregivers(page = 1, limit = 10, filters = '') {
  /*
  # 여성, 경기도, 수원시 장안구
    gender=female|location=10|detail_location=102

    # 성별 무관, 경기도
    location=10

    # 성별 남성, 전국
    gender=male
  */
  const url = `caregiver`
  const queries = {
    page,
    limit,
    filters
  }
  const result = await Api().get(makeGetUrl(url, queries), { cache: true })
  const { data } = result.data
  return data
}

async function getCaregiver(idx = null) {
  const url = `caregiver`
  const queries = {
    filters: `index=${idx}`
  }
  const result = await Api().get(makeGetUrl(url, queries), { cache: true })
  const item = result.data.data.items[0]
  return addConvertedItem(item)
}

async function getCarerByEncrypedString(encStr) {
  const url = `/patient/caregiver/${encStr}`
  try {
    const result = await Api().get(url)
    const { item } = result.data.data
    return addConvertedItem(item)
  } catch (e) {
    throw e.response
  }
}

async function requestAccept(encStr) {
  const url = `/patient/caregiver/accept/${encStr}`
  const apiResult = await Api().post(url)
  const { result } = apiResult.data
  return result
}

async function requestReject(encStr) {
  const url = `/patient/caregiver/reject/${encStr}`
  const apiResult = await Api().post(url)
  const { result } = apiResult.data
  return result
}

export default {
  getCaregivers,
  getCaregiver,
  getCarerByEncrypedString,
  requestAccept,
  requestReject
}
