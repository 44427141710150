import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import AppStore from './app'
import AccountStore from './account'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    [AppStore.namespace]: AppStore,
    [AccountStore.namespace]: AccountStore
  }
})
