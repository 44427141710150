import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/',
    name: 'Main.Index',
    component: importPage('main/Main'),
    meta: {
      title: '',
      scrollToTop: true
    }
  },
  {
    path: '/request_carer/:ENC_STR',
    name: 'Main.Request.Index',
    component: importPage('main/Main'),
    meta: {
      title: '',
      scrollToTop: true
    }
  }
]
